/* eslint-disable @typescript-eslint/indent */
import React, { useState } from 'react';
import Image from 'next/image';
import { Typography } from '@pwskills/rachnaui';
import backIcon from '@/assets/icons/dropdownIcon.svg';
import checkMark from '@/assets/icons/checkmark-outline.svg';
import { capitaliseFirstLetter } from '@/components/CareerPathLearn/Utils/CareerPathUtils';
import { CareerPathStatus } from '@/components/CareerPathLearn/Enums';
import OutsideClickHandler from '../OutsideClickHandler';

export type textAndFunctionType = {
  name: string;
  onClick: () => void;
  status?: string;
  createdAt?: string;
  lessons?: Array<string>;
  title?: string;
  updatedAt?: string;
  _id?: string;
  index?: number;
};
type props = {
  width?: string;
  options: Array<textAndFunctionType>;
  defaultIndex?: number;
};
const Dropdown = ({ options, width, defaultIndex = 0 }: props) => {
  const [selectedValue, setSelectedValue] = useState<textAndFunctionType>(options[defaultIndex]);
  const [dropDownActive, setDropdownActive] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(defaultIndex);
  const returnButtonClassname = (index: number) => {
    let className = 'w-full';
    if (options[index]?.status === CareerPathStatus.unlocked) className += 'cursor-pointer';
    else className += 'cursor-auto';
    if (options.length - 1 === index) className += 'rounded-b';
    else className += 'rounded-none';
    if (index === activeIndex) className += 'bg-gray-100';
    else className += ' bg-white';
    if (index === 0)
      className +=
        'border-t-[1px] border-x-[1px] border-solid border-x-[#EFEFEF] border-t-[#EFEFEF]  !rounded-t';
    if (index === options.length - 1)
      className +=
        'border-y-[1px] border-x-[1px] border-solid border-x-[#EFEFEF] border-y-[#EFEFEF] !rounded-b';
    else
      className +=
        'border-t-[1px] border-x-[1px] border-t-solid border-x-solid border-x-[#EFEFEF] border-t-[#EFEFEF] ';
    if (options[index]?.status !== CareerPathStatus.locked) className += 'hover:bg-[#F8FAFB]';
    return className;
  };
  return (
    <OutsideClickHandler onOutsideClick={() => setDropdownActive(false)}>
      <button
        type="button"
        onClick={() => setDropdownActive(!dropDownActive)}
        // className={`min-w-[190px] flex items-center justify-center`}
      >
        <div
          className={`relative border border-[#EFEFEF] px-4 py-2 items-center ${
            selectedValue?.status === CareerPathStatus.unlocked ? 'cursor-pointer' : ''
          } bg-white rounded ${width}`}
        >
          <div className="w-full flex items-center justify-between gap-2">
            <Typography
              component="body-small"
              className="text-[#3D3D3D] text-ellipsis overflow-hidden whitespace-nowrap"
              variant="bold"
            >
              {capitaliseFirstLetter(selectedValue?.name)}
            </Typography>
            <Image src={backIcon} alt="icon" />
          </div>

          {dropDownActive && (
            <div className="absolute top-[45px] right-[-2px] bg-white shadow-[0_8px_14px_0px_rgba(0,0,0,0.06)] delay-75 z-10 border border-[#EFEFEF] flex flex-col rounded w-[222px] transition-all">
              {options.map((optionssingleObject: textAndFunctionType, index: number) => (
                <button
                  type="button"
                  onClick={() => {
                    if (optionssingleObject?.status === CareerPathStatus.unlocked) {
                      setSelectedValue(optionssingleObject);
                      optionssingleObject.onClick();
                      setActiveIndex(index);
                    }
                  }}
                  className={returnButtonClassname(index)}
                >
                  <div className="px-4 py-2 ">
                    {optionssingleObject?.status !== CareerPathStatus.unlocked && (
                      <div className="relative w-full h-full">
                        <div className="bg-white w-[100%] h-[30px] opacity-70 absolute top-0 z-10" />
                      </div>
                    )}

                    <div className="flex items-center justify-start  w-full gap-2">
                      <Image
                        src={checkMark}
                        alt="icon"
                        className={index === activeIndex ? 'opacity-100' : 'opacity-0'}
                      />
                      <Typography
                        component="body-small"
                        className="text-[#3D3D3D] text-ellipsis overflow-hidden whitespace-nowrap"
                        variant="regular"
                      >
                        {optionssingleObject.name}
                      </Typography>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      </button>
    </OutsideClickHandler>
  );
};

export default Dropdown;
