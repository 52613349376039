import React from 'react';
import { Typography } from '@pwskills/rachnaui';
import Image from 'next/image';
import sortIcon from '@/assets/icons/sort.svg';
import Dropdown, { textAndFunctionType } from '@/components/common/Dropdown';

type Props = {
  title: string;
  children: React.ReactNode;
  classname: string;
  childClass: string;
  dropdown?: Array<textAndFunctionType>;
  button?: textAndFunctionType;
  dropDownWidth?: string;
  dropdownActiveIndex?: number;
  hideButton?: boolean;
};

const ListingLayout = ({
  title,
  children,
  classname,
  childClass,
  dropdown,
  button,
  dropDownWidth,
  dropdownActiveIndex,
  hideButton = false,
}: Props) => {
  return (
    <section className={classname}>
      <div
        className={`flex ${
          button && dropdown ? 'flex-col' : 'flex-row'
        } medium:!flex-row medium:!items-center gap-y-4 justify-between `}
      >
        <div
          className={`flex ${
            button && dropdown ? '' : 'justify-center items-center'
          }  medium:!justify-center medium:!items-center`}
        >
          <Typography className=" text-gray-850 self-start" variant="bold" component="h3">
            {title}
          </Typography>
        </div>

        <div className="flex gap-4 items-center">
          {!hideButton && button && (
            <button
              type="button"
              onClick={() => button.onClick()}
              className="border-[1px] border-solid border-[#EFEFEF] !rounded h-full"
            >
              <div className="bg-white px-4 py-2 !h-full inline-flex flex-row gap-x-1 min-w-[104.4px] !rounded justify-center items-center">
                <Typography
                  variant="regular"
                  component="body-small"
                  className="!text-[14px] !leading-[22px] text-gray-850 font-semibold"
                >
                  {button.name}
                </Typography>
                <Image src={sortIcon} alt="sortIcon" width={16} height={16} />
              </div>
            </button>
          )}
          {dropdown && dropdown?.length > 0 && (
            <Dropdown options={dropdown} width={dropDownWidth} defaultIndex={dropdownActiveIndex} />
          )}
        </div>
      </div>
      <div
        className={
          childClass ||
          'grid grid-cols-1 small:grid-cols-2 medium:grid-cols-3 large:grid-cols-4 mt-4 gap-y-4 gap-x-2'
        }
      >
        {children}
      </div>
    </section>
  );
};

export default ListingLayout;
