import { Typography } from '@pwskills/rachnaui';
import Image from 'next/image';
import React from 'react';
import SearchNotFound from '@/assets/icons/search-not-found.svg';

type propType = {
  message?: string;
};

const EmptyState = ({ message = 'Oh no! Nothing for meantime.' }: propType) => {
  return (
    <div className="w-full h-[200px] flex justify-center items-center">
      <div className="flex flex-col">
        <Image src={SearchNotFound} alt="SearchNotFound" className="w-[240px] h-[160px]" />
        <Typography component="body-regular" className="text-gray-850 text-center">
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default EmptyState;
