import { baseApiUrl } from '@/utils/common/env.constants';
import { get, patch, post } from './index';

export type UserProgressType = {
  userId: string;
  lessonProgressPercentage: number;
  percentile: number;
};

export type UserProgressResponseType = {
  userProgress: UserProgressType;
};

export type UpdateCodingLanguageOrSpecializationDataType = {
  _id: string;
  name: string;
  actionMenuTitle: string;
};

class LearnApi {
  static getUserCareerPath<T>(orderType: number, searchText: string) {
    return get<T>(
      `/v1/learn/user/courses?skip=0&limit=1000&order=${orderType}&search=${searchText}&isCareerPath=true`,
      undefined,
      baseApiUrl
    );
  }

  static getSearchedUserCourses<T>(searchText: string) {
    return get<T>(
      `/v1/learn/user/courses?skip=0&limit=10&order=-1&search=${searchText}`,
      undefined,
      baseApiUrl
    );
  }

  static getUserCourses<T>(orderType: number, isCareerPath: string, searchText: string) {
    return get<T>(
      `/v1/learn/user/courses?skip=0&limit=1000&order=${orderType}&isCareerPath=${isCareerPath}&search=${searchText}`,
      undefined,
      baseApiUrl
    );
  }

  static getDoubtsClasses<T>(
    courseId: string,
    isDoubt: boolean,
    eventTime: string,
    startDate: string,
    endDate: string,
    limit: number,
    skip: number
  ) {
    return get<T>(
      `v2/learn/career-path/get-events/${courseId}?isDoubt=${isDoubt}&eventTime=${eventTime}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&skip=${skip}`,
      undefined,
      baseApiUrl
    );
  }

  static async postSetReminder(courseId: string, data: { eventId: string; type: string }) {
    return post(`v2/learn/career-path/remind-me/${courseId}`, data, undefined, baseApiUrl);
  }

  static getCarrerPathMasterclassAndEvents<T>(courseId: any, eventTime: string) {
    return get<T>(
      `/v2/learn/career-path/get-events/${courseId}?isDoubt=false&eventTime=${eventTime}`,
      undefined,
      baseApiUrl
    );
  }

  static getCareerPathMilestones<T>(courseId: string, yearId: string) {
    return get<T>(`/v2/learn/career-path/${courseId}/${yearId}`, undefined, baseApiUrl);
  }

  static getCareerPathLessons<T>(courseId: any, eventType: string) {
    return get<T>(
      `/v2/learn/career-path/learnings/${courseId}?type=${eventType}`,
      undefined,
      baseApiUrl
    );
  }

  static getUserProgress<T>(courseId: string | string[] | undefined) {
    return get<T>(`v2/learn/progress/${courseId}`, undefined, baseApiUrl);
  }

  static getUserMilestoneProgress<T>(courseId: string | string[] | undefined, milestoneId: string) {
    return get<T>(
      `v2/learn/progress/milestone-progress/${courseId}/${milestoneId}`,
      undefined,
      baseApiUrl
    );
  }

  static getLanguageOrSpecializationData(courseId: string, yearId: string, levelId: string) {
    return get<{
      data: {
        milestones: UpdateCodingLanguageOrSpecializationDataType[];
        defaultMilestoneMeta: {
          limit: number;
          enrolledAt: string;
        };
        existingChosenTypeMilestoneId: string;
      };
    }>(`v2/learn/career-path/${courseId}/${yearId}/${levelId}`, undefined, baseApiUrl);
  }

  static updateCodingLanguageOrSpecializationData(courseId: string, data: { milestoneId: string }) {
    return post(`v2/learn/career-path/choose-milestone/${courseId}`, data, undefined, baseApiUrl);
  }

  static getCareerPathCourseDetails<T>(courseId: string) {
    return get<T>(`v2/learn/career-path/course-details/${courseId}`, undefined, baseApiUrl);
  }

  static getCareerPathSectionDetails<T>(
    courseId: string,
    milestoneId: string,
    sectionId: string,
    isMandatory: string,
    lessonType: string,
    lectureMode: string
  ) {
    let url = `/v2/learn/section/${courseId}/${sectionId}?milestoneId=${milestoneId}&lessonType=${lessonType}`;
    if (lectureMode !== 'null') url += `&lectureMode=${lectureMode}`;
    if (isMandatory !== 'null') {
      if (isMandatory === 'true') url += `&isMandatory=${true}`;
      else url += `&isMandatory=${false}`;
    }
    return get<T>(url, undefined, baseApiUrl);
  }

  static getCertificateDetails<T>(certificateId: string, isCareerPath: boolean) {
    return get<T>(
      `${isCareerPath ? 'v2' : 'v1'}/learn/certificate/${certificateId}`,
      undefined,
      baseApiUrl
    );
  }

  static generateCertificate<T>(
    isCareerPath: boolean,
    courseId: string,
    milestoneId: string,
    token: string
  ) {
    return post<T>(
      `v2/learn/certificate/${courseId}${isCareerPath ? `?milestoneId=${milestoneId}` : ''}
      `,
      {
        'g-recaptcha-response': token,
      },
      undefined,
      baseApiUrl
    );
  }

  static getLessonInfo<T>(courseId: string, lessonId: string) {
    return get<T>(`v1/learn/lesson/course/${courseId}/${lessonId}`, undefined, baseApiUrl);
  }

  static getAllCourseDetails<T>(courseId: string) {
    return get<T>(`v2/course/${courseId}`, undefined, baseApiUrl);
  }

  static getNormalCourseDetails<T>(courseId: string) {
    return get<T>(`/v1/learn/analytics/lastPlayed/course/${courseId}`, undefined, baseApiUrl);
  }

  static updateMilestoneActiveYear(courseId: string, yearId: string) {
    const data = {
      courseId,
      yearId,
    };
    return patch(`/v2/learn/career-path/years-tracker`, data, undefined, baseApiUrl);
  }

  // get user progress in each lesson types
  static getUserCourseProgressForCertificate(courseId: string) {
    return get(`v2/learn/certificate/certificate-progress/${courseId}`, undefined, baseApiUrl);
  }
}

export default LearnApi;
