/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum CareerPathStatus {
  locked = 'locked',
  unlocked = 'unlocked',
  HUNDRED = '100',
  ALL = 'all',
  UNDER_EVALUATION = 'under_evaluation',
  SUBMITTED = 'submitted',
  EVALUATED = 'evaluated',
}
