// eslint-disable-next-line import/prefer-default-export
export const returnLessonTypeFunction = (activeLessonType: string, forSidebar: boolean) => {
  switch (activeLessonType) {
    case 'video':
      return 'video';
    case 'notes':
      if (forSidebar) return 'video';
      return 'notes';
    case 'quiz':
      return 'quiz_v2';
    case 'test':
      return 'test';
    case 'assignment':
      return 'assignment_v2';
    default:
      return 'video';
  }
};
export const capitaliseFirstLetter = (str: string) => {
  const firstChar = str.charAt(0).toUpperCase();
  const remainingChars = str.slice(1);
  return `${firstChar}${remainingChars}`;
};
