/* eslint-disable @typescript-eslint/indent */
import { create } from 'zustand';

type DownloadCertificateMeta = {
  canGenerateCertificate: boolean;
  certificateBenchmark: number;
};

type CourseDownloadCertificateMetaDetails = {
  downloadCertificateMeta: DownloadCertificateMeta | null;
  updateDownloadCertificateMeta: (data: DownloadCertificateMeta) => void;
  resetDownloadCertificateMeta: () => void;
};

const useDownloadCertificateStore = create<CourseDownloadCertificateMetaDetails>((set) => ({
  downloadCertificateMeta: null,
  updateDownloadCertificateMeta: (data) => {
    set(() => ({
      downloadCertificateMeta: {
        ...data,
      },
    }));
  },
  resetDownloadCertificateMeta: () => {
    set(() => ({
      downloadCertificateMeta: null,
    }));
  },
}));

export default useDownloadCertificateStore;
